import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function Custom404() {
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    const pathNameArray = window.location.pathname.split("/");

    if (pathNameArray.length >= 2 && pathNameArray[1] === "join") {
      router.replace("/join/[uuid]", `/join/${pathNameArray[2]}`);
    } else {
      setIsNotFound(true);
    }
  }, []);

  if (isNotFound) {
    return <h1>404 - Page Not Found</h1>;
  }

  return null;
}
